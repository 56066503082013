import React, { useEffect } from "react"
import { Table } from "react-bootstrap"
import { RichText } from "prismic-reactjs"

const ThCells = ({ row, total_cells = 3 }) => {
  const arr = []
  for (var i = 1; i <= total_cells; i++) {
    arr.push(i.toString())
  }

  return (
    <tr>
      {arr.map(i => {
        return (
          <th key={`cell_${i}`} className="rich-content">
            {row[`cell_${i}`] ? RichText.render(row[`cell_${i}`].raw) : "..."}
          </th>
        )
      })}
    </tr>
  )
}

const TrCells = ({ row, total_cells = 3 }) => {
  const arr = []
  for (var i = 1; i <= total_cells; i++) {
    arr.push(i.toString())
  }

  useEffect(() => {
    var els = document.querySelectorAll("a")

    for (var i = 0, l = els.length; i < l; i++) {
      var el = els[i]
      el.target = "_blank"
    }
  }, [])

  return (
    <tr>
      {arr.map(i => {
        return (
          <td key={`cell_${i}`} className="rich-content">
            {row[`cell_${i}`] ? RichText.render(row[`cell_${i}`].raw) : "..."}
          </td>
        )
      })}
    </tr>
  )
}

const TableSlice = ({ total_cells, title, items }) => (
  <div className="overflow-hidden mb-3">
    {title && <h4>{title}</h4>}
    {items.length > 1 && (
      <Table
        responsive
        size="sm"
        className="mb-4 mb-lg-5"
        striped
        bordered
        hover
      >
        <thead className="table-head">
          {items.map((row, index) => {
            return (
              index === 0 && (
                <ThCells key={index} row={row} total_cells={total_cells} />
              )
            )
          })}
        </thead>
        <tbody>
          {items.map((row, index) => {
            return (
              index > 0 && (
                <TrCells key={index} row={row} total_cells={total_cells} />
              )
            )
          })}
        </tbody>
      </Table>
    )}
  </div>
)

export default TableSlice
