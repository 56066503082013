import React from "react"
import styled from "styled-components"

import { device } from "../../utils"
import { Row, Col } from "react-bootstrap"

const ImgCard = styled.div`
  max-width: 95%;
  width: 100%;
  @media ${device.sm} {
    width: 50%;
  }
  @media ${device.md} {
    width: 40%;
  }
  @media ${device.lg} {
    width: 30%;
  }
  img {
    width: 100%;
  }
`

const ArticleStyled = styled.article`
  overflow: hidden;

  blockquote {
    border-left: 4px solid #222;
    font-size: 1.05rem;
    padding: 20px;
  }
  img,
  iframe,
  video {
    max-width: 100%;
    display: block;
  }
`

const GallerySlice = ({ title, items }) => {
  return (
    <ArticleStyled className="mb-3">
      {title && <h4 className="mb-4">{title}</h4>}
      {items.length > 0 && (
        <>
          <Row className="d-flex justify-content-center">
            {items.map((item, index) => {
              return (
                item.image && (
                  <Col lg="4" key={index} className="mb-3 mb-lg-4 text-center">
                    <ImgCard className="w-100">
                      <div className="w-100">
                        <img
                          src={item.image.url}
                          alt={
                            (item.caption && item.caption.text) ||
                            `Image ${index + 1}`
                          }
                          className="w-100"
                        />
                      </div>
                      {item.caption && (
                        <h6 className="mt-2">{item.caption.text}</h6>
                      )}
                    </ImgCard>
                  </Col>
                )
              )
            })}
          </Row>
        </>
      )}
    </ArticleStyled>
  )
}

export default GallerySlice
