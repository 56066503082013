import React from "react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

import SectionSlice from "./SectionSlice"
import TableSlice from "./TableSlice"
import GallerySlice from "./GallerySlice"

export const renderSlices = (type, primary = null, items = [], key = null) => {
  switch (type) {
    case "section":
      return (
        <SimpleReactLightbox key={key}>
          <SRLWrapper>
            <SectionSlice title={primary.title1.text} items={items} />
          </SRLWrapper>
        </SimpleReactLightbox>
      )
    case "table":
      return (
        <SimpleReactLightbox key={key}>
          <SRLWrapper>
            <TableSlice
              total_cells={primary.total_cells}
              title={primary.title1.text}
              items={items}
            />
          </SRLWrapper>
        </SimpleReactLightbox>
      )
    case "image_gallery":
      return (
        <SimpleReactLightbox key={key}>
          <SRLWrapper>
            <GallerySlice title={primary.title1.text} items={items} />{" "}
          </SRLWrapper>
        </SimpleReactLightbox>
      )

    default:
      return null
  }
}
