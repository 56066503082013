import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { Accordion, Card } from "react-bootstrap"
import { useLocation } from "@reach/router"
import { FaChevronRight } from "react-icons/fa"

import GlobalContext from "../../contexts/GlobalContext"
import { Box } from "../Core"
import { slugSubCat, slugCat } from "../../utils/slugs"
import { itemsByLang } from "../../utils/helperFn"

const CardStyled = styled(Card)`
  .card-header {
    cursor: pointer;
    font-weight: bold;
  }
  ul {
    margin-bottom: 0;
  }

  &.accordion-card-right,
  &.accordion-card-right > .card-header {
    background: ${({ theme }) => theme.colors.lightShade} !important;
    transition: all 0.2s ease-out;
    border-color: ${({ theme }) => theme.colors.border} !important;
    user-select: none;
  }

  &.accordion-card-right > .card-header {
    font-weight: 600;
  }

  .card-body,
  &.accordion-card-right:not(.active) > .card-header:hover {
    color: ${({ theme }) => theme.colors.dark} !important;
    background-color: #ffffff !important;
  }

  &.accordion-card-right.active > .card-header {
    color: #fff !important;
    background: ${({ theme, colorBrand }) =>
      colorBrand ? colorBrand : theme.colors.primary} !important;
    border-color: ${({ theme }) => theme.colors.border} !important;
  }

  a.subcat-link {
    transition: all 0.15s ease-out;
    user-select: none;
    &:hover {
      color: ${({ theme, colorHover }) =>
        colorHover ? colorHover : theme.colors.primary} !important;
      text-decoration: none !important;
      outline: none !important;
      font-weight: 600;
    }
    &.active {
      text-decoration: none !important;
      outline: none !important;
      color: ${({ theme, colorActive }) =>
        colorActive ? colorActive : theme.colors.primary} !important;
      font-weight: 600;
    }
  }

  .accordion-header {
    position: relative;
    padding-right: 40px;
    &.active a {
      color: #fff !important;
    }
  }
`

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  position: absolute;
  right: 5px;
  top: 50%;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;

  & > svg {
    transition: 0.3s;
  }

  &.active > svg {
    color: #fff;
    transform: rotate(90deg);
  }
`

const AccordionC = ({ className }) => {
  const { pathname } = useLocation()
  const gContext = useContext(GlobalContext)

  const { allPrismicCategory, allPrismicSubCategory } = useStaticQuery(graphql`
    query {
      allPrismicCategory(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            title {
              text
            }
            color_brand
            color_hover
            color_active
          }
        }
      }
      allPrismicSubCategory(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            category {
              uid
            }
            title {
              text
            }
          }
        }
      }
    }
  `)

  const catsByLang = itemsByLang(allPrismicCategory.nodes, gContext.currentLang)

  const subCatsByLang = itemsByLang(
    allPrismicSubCategory.nodes,
    gContext.currentLang
  )

  const subCatsByCat = cat => {
    return subCatsByLang.filter(
      ({ data }) => data.category && data.category.uid === cat.uid
    )
  }

  const [activeCatKey, setActiveCatKey] = useState(
    catsByLang.length > 0 ? catsByLang[0].uid : null
  )

  useEffect(() => {
    const filteredCatItems = catsByLang.filter(
      cat => pathname.indexOf(cat.uid) !== -1
    )

    if (filteredCatItems.length > 0) {
      setActiveCatKey(filteredCatItems[0].uid)
    }
  }, [pathname])

  return (
    <>
      <Box className={className} pb={4}>
        <Accordion activeKey={activeCatKey}>
          {catsByLang.map(cat => {
            const { color_brand, color_hover, color_active } = cat.data
            return (
              <CardStyled
                key={cat.uid}
                className={`accordion-card-right ${
                  activeCatKey === cat.uid ? "active" : ""
                }`}
                colorBrand={color_brand}
                colorActive={color_active}
                colorHover={color_hover}
              >
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={cat.uid}
                  className={
                    activeCatKey === cat.uid
                      ? "accordion-header active"
                      : "accordion-header"
                  }
                >
                  <Link to={slugCat(cat.uid, gContext.currentLang)}>
                    {cat.data.title.text}
                  </Link>
                  <ToggleButton
                    onClick={() => {
                      if (activeCatKey !== cat.uid) {
                        setActiveCatKey(cat.uid)
                      } else {
                        setActiveCatKey(null)
                      }
                    }}
                    className={activeCatKey === cat.uid ? "active" : ""}
                  >
                    <FaChevronRight size={12} />
                  </ToggleButton>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={cat.uid}>
                  <Card.Body>
                    <ul>
                      {subCatsByCat(cat).map(subCat => {
                        const isActive = pathname.indexOf(subCat.uid) !== -1

                        return (
                          <li key={subCat.uid}>
                            <Link
                              to={slugSubCat(
                                subCat.uid,
                                subCat.data.category.uid,
                                gContext.currentLang
                              )}
                              className={`subcat-link ${
                                isActive ? "active" : ""
                              }`}
                            >
                              {subCat.data.title.text}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </CardStyled>
            )
          })}
        </Accordion>
      </Box>
    </>
  )
}

export default AccordionC
