import React, { useContext } from "react"
import { Button } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled, { keyframes } from "styled-components"
import Slider from "react-slick"

import GlobalContext from "../../contexts/GlobalContext"
import { Box } from "../../components/Core"
import { slugCat } from "../../utils/slugs"
import { itemsByLang } from "../../utils/helperFn"

const keyKenburn = keyframes`
  0%{
    transform-origin: center;
    transform: scale(1.2);
  }
  100%{
    transform: scale(1.0);
  }
`

const SliderStyled = styled(Slider)`
  .slick-prev,
  .slick-next {
    z-index: 100;
  }
  .slick-prev {
    left: 5px;
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
    opacity: 1;
    color: #fff;
  }

  a:focus {
    color: #fff !important;
    background: transparent !important;
  }
`

const SliderItemSltyled = styled(Box)`
  height: 500px;
  width: 100%;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .slick-slide.slick-active.slick-current & .cover-image {
    animation: ${keyKenburn} 7s linear;
  }
  .slick-slide & .text-wrapper {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease-out 0.5s;
  }
  .slick-slide.slick-active.slick-current & .text-wrapper {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`
const CoverImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
`

const SliderItem = ({ cat, btnText }) => {
  const gContext = useContext(GlobalContext)
  return (
    <SliderItemSltyled
      p="40px"
      className="text-center text-white d-flex align-items-center justify-content-center"
    >
      {cat.data.cover_image_home && (
        <CoverImage
          className="cover-image"
          fluid={cat.data.cover_image_home.fluid}
          alt={cat.data.title.text}
        />
      )}

      <div
        className="position-relative text-wrapper"
        css={`
          z-index: 10;
        `}
      >
        {cat.data.logo && (
          <Box mb={3}>
            <Img
              className="logo-image"
              fixed={cat.data.logo.fixed}
              alt={cat.data.title ? cat.data.title.text : ""}
            />
          </Box>
        )}
        <h3 className="text-white">{cat.data.title.text}</h3>
        <p className="text-white my-4">{cat.data.intro.text}</p>
        <Link to={slugCat(cat.uid, gContext.currentLang)}>
          <Button variant="outline-light">{btnText}</Button>
        </Link>
      </div>
    </SliderItemSltyled>
  )
}

const ProductSlider = () => {
  const { allPrismicCategory, allPrismicCommon } = useStaticQuery(graphql`
    query {
      allPrismicCommon {
        nodes {
          lang
          data {
            btn_sidebar_slider {
              text
            }
          }
        }
      }
      allPrismicCategory(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            title {
              text
            }
            intro {
              text
            }
            logo {
              fixed(width: 100) {
                ...GatsbyPrismicImageFixed
              }
            }
            cover_image_home {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)
  const catsByLang = itemsByLang(allPrismicCategory.nodes, gContext.currentLang)

  const prismicCommon = itemsByLang(
    allPrismicCommon.nodes,
    gContext.currentLang
  )[0]

  const { btn_sidebar_slider } = prismicCommon?.data

  console.log(btn_sidebar_slider)

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnHover: false,
  }
  return (
    <>
      <SliderStyled {...settings}>
        {catsByLang.map(cat => (
          <SliderItem
            cat={cat}
            key={cat.uid}
            btnText={btn_sidebar_slider?.text || "See Details"}
          />
        ))}
      </SliderStyled>
    </>
  )
}

export default ProductSlider
