import React, { useEffect } from "react"
import styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import { RichText } from "prismic-reactjs"

const OneCol = ({ items }) => (
  <Col xs="12" className="mb-3 mb-lg-4 rich-content">
    {RichText.render(items[0].div.raw)}
  </Col>
)

const TwoCols = ({ items }) => {
  return items.map(({ div }, index) => (
    <Col lg="6" key={index} className="mb-3 mb-lg-4 rich-content">
      {RichText.render(div.raw)}
    </Col>
  ))
}
const ThreeCols = ({ items }) => {
  return items.map(({ div }, index) => (
    <Col lg="4" key={index} className="mb-3 mb-lg-4 rich-content">
      {RichText.render(div.raw)}
    </Col>
  ))
}

const ArticleStyled = styled.article`
  overflow: hidden;

  blockquote {
    border-left: 4px solid #222;
    font-size: 1.05rem;
    padding: 20px;
  }
  img,
  iframe,
  video {
    width: 100%;
    max-width: 100%;
    display: block;
  }
`

const SectionSlice = ({ title, items }) => {
  useEffect(() => {
    var els = document.querySelectorAll("a")

    for (var i = 0, l = els.length; i < l; i++) {
      var el = els[i]
      el.target = "_blank"
    }
  }, [])

  return (
    <ArticleStyled className="mb-3">
      {title && <h3 className="mb-4">{title}</h3>}
      {items.length > 0 && (
        <Row>
          {items.length === 1 ? (
            <OneCol items={items} />
          ) : items.length === 2 ? (
            <TwoCols items={items} />
          ) : (
            <ThreeCols items={items} />
          )}
        </Row>
      )}
    </ArticleStyled>
  )
}

export default SectionSlice
